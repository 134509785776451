/* eslint-disable react/no-danger */
import PropTypes from 'prop-types'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { Heading } from '../../typography/Heading'
import { Eyebrow } from '../../typography/Eyebrow'
import { Container, Row, Col } from '../../grid'
import { Button } from '../../button'
import { RichText } from '../../rich-text'
import { Image } from '../../image'
import * as styles from './ImageCopyModule.module.scss'

/* eslint-disable-next-line */
const ButtonElement = ({ buttonCta, className, buttonUrl, buttonColor, alt, isModal, modalUrlIsMember,
  modalUrlNonMember,
  buttonTarget = '_self',
  ...props
}) => {
  if (buttonColor === 'light') {
    if (isModal) {
      return (
        <Button
          variant="secondary"
          alt={alt}
          target={buttonTarget}
          isModal={isModal}
          {...props}
          modalUrlIsMember={modalUrlIsMember}
          modalUrlNonMember={modalUrlNonMember}
        >
          {buttonCta}
        </Button>
      )
    }
    return (
      <Button
        url={buttonUrl}
        variant="secondary"
        alt={alt}
        target={buttonTarget}
        isModal={isModal}
        {...props}
      >
        {buttonCta}
      </Button>
    )
  }
  if (isModal) {
    return (
      <Button
        isModal={isModal}
        target={buttonTarget}
        alt={alt}
        {...props}
        modalUrlIsMember={modalUrlIsMember}
        modalUrlNonMember={modalUrlNonMember}
      >
        {buttonCta}
      </Button>
    )
  }

  return (
    <Button url={buttonUrl} target={buttonTarget} alt={alt} {...props}>
      {buttonCta}
    </Button>
  )
}

const ImageCopyModule = ({
  richTagline,
  richHeadline,
  image,
  imageCaption,
  richBody,
  imagePosition,
  buttonCtaOne,
  buttonUrlOne,
  buttonColorOne,
  buttonOneIsModal,
  buttonTargetOne = '_self',
  buttonAltOne,
  buttonCtaTwo,
  buttonUrlTwo,
  buttonTwoIsModal,
  buttonColorTwo,
  buttonTargetTwo = '_self',
  buttonAltTwo,
  imageBackgroundColor,
  disclaimer,
  modalUrlIsMember,
  modalUrlNonMember,
  theme,
}) => {
  const bgImageLeftColor = {
    left: '-24px',
    height: '100%',
    position: 'absolute',
    top: '0',
    width: '90%',
    zIndex: '-1',
  }
  const bgImageRightColor = {
    height: '100%',
    position: 'absolute',
    right: '-24px',
    top: '0',
    width: '90%',
    zIndex: '-1',
  }

  const wrapperColor = {
    zIndex: '-2',
  }
  let isLightText

  if (imageBackgroundColor && imageBackgroundColor === 'tan') {
    bgImageLeftColor.backgroundColor = '#f7f1dc'
    bgImageRightColor.backgroundColor = '#f7f1dc'
  }

  if (imageBackgroundColor && imageBackgroundColor === 'teal') {
    bgImageLeftColor.backgroundColor = '#8cbac3'
    bgImageRightColor.backgroundColor = '#8cbac3'
  }

  if (theme === 'lightGray') {
    wrapperColor.backgroundColor = '#EFEFEF'
    isLightText = false
    // dark button and dark text
  } else if (theme === 'creme') {
    wrapperColor.backgroundColor = '#FDFBF5'
    isLightText = false
  } else if (theme === 'personalBlue') {
    wrapperColor.backgroundColor = '#00587C'
    isLightText = true
  } else if (theme === 'businessGray') {
    wrapperColor.backgroundColor = '#415563'
    isLightText = true
  } else {
    wrapperColor.backgroundColor = 'transparent'
  }

  const innerContentLeft = classNames(styles.contentContainerInner)
  const innerContentRight = classNames(styles.contentContainerInner)

  const imgSrcSetsHalf = [
    {
      width: 700,
      media: '(min-width: 601px)',
    },
    {
      width: 600,
      media: '(max-width: 600px)',
    },
  ]

  const imgSrcSetsTop = [
    {
      width: 1400,
      media: '(min-width: 601px)',
    },
    {
      width: 600,
      media: '(max-width: 600px)',
    },
  ]

  if (image === undefined) {
    return (
      <div>
        <strong>Image copy does not have a image selected!</strong>
      </div>
    )
  }
  if (imagePosition === 'right') {
    return (
      <div style={wrapperColor}>
        <Container>
          <Row>
            <Col xs={12} lg={6}>
              <div className={styles.contentContainer}>
                <div className={innerContentLeft}>
                  {richTagline && (
                    <RichText
                      overrides={{
                        paragraph: (node, children) =>
                          !isEmpty(children) && (
                            <Eyebrow
                              element="h2"
                              className={isLightText ? styles.taglineLight : styles.tagline}
                            >
                              {children}
                            </Eyebrow>
                          ),
                      }}
                    >
                      {richTagline}
                    </RichText>
                  )}

                  <RichText
                    overrides={{
                      paragraph: (node, children) =>
                        !isEmpty(children) && (
                          <Heading
                            element={richTagline ? 'h3' : 'h2'}
                            size={3}
                            className={isLightText ? styles.headingLight : styles.heading}
                          >
                            {children}
                          </Heading>
                        ),
                    }}
                  >
                    {richHeadline}
                  </RichText>
                  <div
                    className={isLightText ? styles.copyLight : styles.copy}
                    data-qa="image-copy-module-copy"
                  >
                    <RichText>{richBody}</RichText>
                  </div>

                  {buttonCtaOne && (
                    <div className={styles.buttonRow} data-qa="image-copy-module-buttons">
                      <ButtonElement
                        buttonColor={buttonColorOne}
                        buttonUrl={buttonUrlOne}
                        buttonCta={buttonCtaOne}
                        buttonTarget={buttonTargetOne}
                        alt={buttonAltOne}
                        modalUrlIsMember={modalUrlIsMember}
                        modalUrlNonMember={modalUrlNonMember}
                      />

                      {buttonCtaTwo && (
                        <ButtonElement
                          buttonColor={buttonColorTwo}
                          buttonUrl={buttonUrlTwo}
                          buttonCta={buttonCtaTwo}
                          buttonTarget={buttonTargetTwo}
                          alt={buttonAltTwo}
                          modalUrlIsMember={modalUrlIsMember}
                          modalUrlNonMember={modalUrlNonMember}
                        />
                      )}
                    </div>
                  )}

                  {disclaimer && (
                    <div className={styles.disclaimer} data-qa="image-copy-module-disclaimer">
                      <RichText>{disclaimer}</RichText>
                    </div>
                  )}
                </div>
              </div>
            </Col>

            <Col xs={12} lg={6} className={styles.imageContainerRightColumn}>
              <div
                className={styles.imageContainerRight}
                data-qa="image-copy-module-right-container"
              >
                <Image
                  type={image.contentType}
                  src={image.url}
                  alt={image.description}
                  data-qa="image-copy-module-right-image"
                  srcSets={imgSrcSetsHalf}
                />
                <div style={bgImageRightColor} aria-hidden="true" />
                {imageCaption && (
                  <div
                    className={styles.caption}
                    style={{ textAlign: 'right' }}
                    data-qa="image-copy-module-disclaimer"
                  >
                    <RichText>{imageCaption}</RichText>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  if (imagePosition === 'top') {
    return (
      <div style={wrapperColor}>
        <Container>
          <Row className={styles.imageTextModuleTop}>
            <Col xs={12}>
              <div>
                <Image
                  type={image.contentType}
                  src={image.url}
                  alt={image.description}
                  data-qa="image-copy-module-top-image"
                  srcSets={imgSrcSetsTop}
                />
                {imageCaption && (
                  <div className={styles.caption} data-qa="image-copy-module-disclaimer">
                    <RichText>{imageCaption}</RichText>
                  </div>
                )}
              </div>
            </Col>

            <Col xs={12}>
              <div className={styles.contentContainer}>
                <div className={styles.contentContainerInner}>
                  {richTagline && (
                    <RichText
                      overrides={{
                        paragraph: (node, children) =>
                          !isEmpty(children) && (
                            <Eyebrow
                              element="h2"
                              className={isLightText ? styles.taglineLight : styles.tagline}
                            >
                              {children}
                            </Eyebrow>
                          ),
                      }}
                    >
                      {richTagline}
                    </RichText>
                  )}
                  <RichText
                    overrides={{
                      paragraph: (node, children) =>
                        !isEmpty(children) && (
                          <Heading
                            element={richTagline ? 'h3' : 'h2'}
                            size={3}
                            className={isLightText ? styles.headingLight : styles.heading}
                          >
                            {children}
                          </Heading>
                        ),
                    }}
                  >
                    {richHeadline}
                  </RichText>
                  <div
                    className={isLightText ? styles.copyLight : styles.copy}
                    data-qa="image-copy-module-copy"
                  >
                    <RichText>{richBody}</RichText>
                  </div>

                  {buttonCtaOne && (
                    <div className={styles.buttonRow} data-qa="image-copy-module-buttons">
                      <ButtonElement
                        buttonColor={buttonColorOne}
                        buttonUrl={buttonUrlOne}
                        buttonCta={buttonCtaOne}
                        buttonTarget={buttonTargetOne}
                        alt={buttonAltOne}
                        modalUrlIsMember={modalUrlIsMember}
                        modalUrlNonMember={modalUrlNonMember}
                      />

                      {buttonCtaTwo && buttonUrlTwo && buttonColorTwo && (
                        <ButtonElement
                          buttonColor={buttonColorTwo}
                          buttonUrl={buttonUrlTwo}
                          buttonCta={buttonCtaTwo}
                          buttonTarget={buttonTargetTwo}
                          alt={buttonAltTwo}
                          modalUrlIsMember={modalUrlIsMember}
                          modalUrlNonMember={modalUrlNonMember}
                        />
                      )}
                    </div>
                  )}
                  {disclaimer && (
                    <div className={styles.disclaimer} data-qa="image-copy-module-disclaimer">
                      <RichText>{disclaimer}</RichText>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  return (
    <div style={wrapperColor}>
      <Container>
        <Row>
          <Col xs={12} lg={6}>
            <div className={styles.imageContainerLeft} data-qa="image-copy-module-left-container">
              <Image
                type={image.contentType}
                src={image.url}
                alt={image.description}
                srcSets={imgSrcSetsHalf}
              />
              <div style={bgImageLeftColor} aria-hidden="true" />
              {imageCaption && (
                <div
                  className={styles.caption}
                  data-qa="image-copy-module-disclaimer"
                  style={{ textAlign: 'left' }}
                >
                  <RichText>{imageCaption}</RichText>
                </div>
              )}
            </div>
          </Col>

          <Col xs={12} lg={6}>
            <div className={styles.contentContainer}>
              <div className={innerContentRight}>
                {richTagline && (
                  <RichText
                    overrides={{
                      paragraph: (node, children) =>
                        !isEmpty(children) && (
                          <Eyebrow
                            element="h2"
                            className={isLightText ? styles.taglineLight : styles.tagline}
                          >
                            {children}
                          </Eyebrow>
                        ),
                    }}
                  >
                    {richTagline}
                  </RichText>
                )}

                <RichText
                  overrides={{
                    paragraph: (node, children) =>
                      !isEmpty(children) && (
                        <Heading
                          element={richTagline ? 'h3' : 'h2'}
                          size={3}
                          className={isLightText ? styles.headingLight : styles.heading}
                        >
                          {children}
                        </Heading>
                      ),
                  }}
                >
                  {richHeadline}
                </RichText>
                <div
                  className={isLightText ? styles.copyLight : styles.copy}
                  data-qa="image-copy-module-copy"
                >
                  <RichText>{richBody}</RichText>
                </div>

                {buttonCtaOne && buttonUrlOne && buttonColorOne && (
                  <div className={styles.buttonRow} data-qa="image-copy-module-buttons">
                    <ButtonElement
                      buttonColor={buttonColorOne}
                      buttonUrl={buttonUrlOne}
                      buttonCta={buttonCtaOne}
                      buttonTarget={buttonTargetOne}
                      alt={buttonAltOne}
                      isModal={buttonOneIsModal}
                      modalUrlIsMember={modalUrlIsMember}
                      modalUrlNonMember={modalUrlNonMember}
                    />

                    {buttonCtaTwo && buttonUrlTwo && buttonColorTwo && (
                      <ButtonElement
                        buttonColor={buttonColorTwo}
                        buttonUrl={buttonUrlTwo}
                        buttonCta={buttonCtaTwo}
                        buttonTarget={buttonTargetTwo}
                        alt={buttonAltTwo}
                        isModal={buttonTwoIsModal}
                        modalUrlIsMember={modalUrlIsMember}
                        modalUrlNonMember={modalUrlNonMember}
                      />
                    )}
                  </div>
                )}

                {disclaimer && (
                  <div className={styles.disclaimer} data-qa="image-copy-module-disclaimer">
                    <RichText>{disclaimer}</RichText>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

ImageCopyModule.propTypes = {
  richTagline: PropTypes.object,
  richHeadline: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  imageCaption: PropTypes.object,
  richBody: PropTypes.object.isRequired,
  imagePosition: PropTypes.string.isRequired,
  buttonCtaOne: PropTypes.string,
  buttonUrlOne: PropTypes.string,
  buttonColorOne: PropTypes.string,
  buttonTargetOne: PropTypes.string,
  buttonAltOne: PropTypes.string,
  buttonCtaTwo: PropTypes.string,
  buttonUrlTwo: PropTypes.string,
  buttonColorTwo: PropTypes.string,
  buttonTargetTwo: PropTypes.string,
  buttonAltTwo: PropTypes.string,
  imageBackgroundColor: PropTypes.string,
  disclaimer: PropTypes.object,
  buttonOneIsModal: PropTypes.bool,
  buttonTwoIsModal: PropTypes.bool,
  modalUrlIsMember: PropTypes.string,
  modalUrlNonMember: PropTypes.string,
}

export { ImageCopyModule }
export default ImageCopyModule
